
const featuredProducts = document.querySelectorAll('.featured-products__carousel:not(.swiper-initialized)');

if (featuredProducts) {
    featuredProducts.forEach(carousel => {
        new Swiper(carousel, {
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 20,
            initialSlide: 1,
            simulateTouch: false,
            loop: true,
            navigation: {
                nextEl: carousel.querySelector('.featured-products__btn-next'),
                prevEl: carousel.querySelector('.featured-products__btn-prev'),
            },
        });
    });
}